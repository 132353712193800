interface IProps {
    containerClasses?: string
    contentPadding?: string
}

const LoadingCard = (props: IProps) => {

    return (
        <div className={`unit-card relative bg-white flex flex-col ${ props.containerClasses || 'mx-3 mb-8 border border-gray-e6' }`}>
            <div className="relative" style={{ paddingTop: '66.66%' }}>
                <div className="absolute inset-0 block LazyLoad" />
            </div>
            <div className={`${props.contentPadding || 'p-2'} flex flex-col`}>
                <div className="LazyLoad rounded-md" style={{ height: 25, marginBottom: 10, width: '85%' }} />
                <div className="LazyLoad rounded-md" style={{ height: 17, marginBottom: 10, width: '60%' }} />
                <div className="LazyLoad rounded-md" style={{ height: 25, marginBottom: 43, width: '40%' }} />
                {/* <div className="LazyLoad rounded-md" style={{ height: 25, width: '70%' }} /> */}
            </div>
        </div>
    )
}

export default LoadingCard